import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./navbar.js"

window.onSubmit = onSubmit;

function onSubmit(token) {
  const form = document.forms['contact-form'];

  if (form.reportValidity()) {
    const name = form.elements['name'].value.trim();
    const email = form.elements['email'].value.trim();
    const message = form.elements['message'].value.trim();
    const honeypot = form.elements['honeypot'].value;

    // Example of checking the honeypot to prevent spam
    if (honeypot) {
      // It's a bot
      return;
    }

    if (!name || !email || !message) {
      return;
    }

    const formData = {
      name: name,
      email: email,
      message: message,
      dateSubmitted: new Date().toISOString(),
      recaptchaToken: token,
    };

    showLoadingContactForm();

    fetch('https://submitcontactform-d2uw5ps65q-uc.a.run.app', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async response => {
        if (response.status === 200) {
          showSuccessToast((await response.json()).message);
        } else {
          showErrorToast((await response.json()).message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      }).finally(_ => {
      hideLoadingContactForm();
    });
  }
}

function showLoadingContactForm() {
  document.getElementById('contactFormLoading').classList.remove('d-none');
  document.getElementById('contactFormSubmit').classList.add('d-none');
}

function hideLoadingContactForm() {
  document.getElementById('contactFormLoading').classList.add('d-none');
  document.getElementById('contactFormSubmit').classList.remove('d-none');
}

function showSuccessToast(message) {
  if (message) {
    const toast = document.getElementById('successToast');
    const toastBody = document.getElementById('successToastBody');

    try {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
      toastBody.innerText = message;
      toastBootstrap.show();
    } catch (error) {
      console.error(error);
    }
  }
}

function showErrorToast(message) {
  if (message) {
    const toast = document.getElementById('errorToast');
    const toastBody = document.getElementById('errorToastBody');

    try {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
      toastBody.innerText = message;
      toastBootstrap.show();
    } catch (error) {
      console.error(error);
    }
  }
}